<template>
    <lottie :options="options" />
</template>

<script>
    import Lottie from '@/js/components/Lottie.vue';
    import animationData from '@/animations/logo-full-white.json';

    export default {
        components: {
            Lottie,
        },

        data() {
            return {
                options: {
                    animationData,
                    autoplay: true,
                    loop: false,
                },
            };
        },
    };
</script>
