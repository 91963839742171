// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp, defineAsyncComponent } from 'vue';

// Components
import Logo from './components/Logo.vue';
import Accordion from './components/Accordion.vue';
import Rot13Email from './components/Rot13Email.vue';
import CollapseTransition from './components/CollapseTransition.vue';

const HeroLogo = defineAsyncComponent(() => import(/* webpackChunkName: "hero-logo" */ './components/HeroLogo.vue'));
const ScrollableLogo = defineAsyncComponent(() => import(/* webpackChunkName: "scrollable-logo" */ './components/ScrollableLogo.vue'));

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import {
    Autoplay,
    Pagination,
    Navigation,
    Lazy,
} from 'swiper';

import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const app = createApp({
    delimiters: [ '${', '}' ],

    components: {
        Swiper,
        SwiperSlide,
    },

    data() {
        return {
            swiperModules: [ Autoplay, Pagination, Navigation, Lazy ],
            isMenuOpen: false,
        };
    },

    methods: {
        async onToggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;

            if (this.isMenuOpen) {
                await this.$nextTick();
                disableBodyScroll(this.$refs.menuScroll);

                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
            } else {
                clearAllBodyScrollLocks();
            }
        },
    },
});

app.component('Logo', Logo);
app.component('Accordion', Accordion);
app.component('HeroLogo', HeroLogo);
app.component('ScrollableLogo', ScrollableLogo);
app.component('Rot13Email', Rot13Email);
app.component('CollapseTransition', CollapseTransition);
app.mount('#app');
